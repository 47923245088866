import { AvalancheTestnet } from "@usedapp/core";
import { Contract } from "ethers";

import PresaleABI from "../contracts/artifacts/contracts/PresaleUpgradeable.sol/PresaleUpgradeable.json"

export const TokenName = "Cannabis";
export const TokenSymbol = "CBN";

export const useSupportedNetworks = {
    [AvalancheTestnet.chainId]: {
        token:{
            contractAddress: "0xa69a18251c4B8f96cdA921AF7edDaED2cA5cDe18",
            name: TokenName,
            symbol: TokenSymbol,
            decimals: 18
        },
        native:{
            contractAddress:"",
            name: AvalancheTestnet?.nativeCurrency?.name,
            symbol: AvalancheTestnet?.nativeCurrency?.symbol,
            decimals: AvalancheTestnet?.nativeCurrency?.decimals
        },

        presaleAddress:  "0xE9c3465dBF7108714BCe205Ba791d3C17c53C54B",
        presaleContractInterface: new Contract("0xE9c3465dBF7108714BCe205Ba791d3C17c53C54B",
        PresaleABI?.abi
        ), 

        explorerLink:{
            name:"snowtrace.com"
        },
        nativeFunctions: AvalancheTestnet,
    },
};