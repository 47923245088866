import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./components/ColorModeSwitcher";

import {Footer, Nav, SwapUI} from "./components";


export const App = () => (
  <VStack w="full" bgColor={useColorModeValue("gray.200","gray.700")}>
    <Nav />
    <VStack  w="full" minH={"100vh"} pt={20}>
      <SwapUI />
    </VStack>
    <Footer />
  </VStack>
);

