import { Button, HStack, Spacer, useColorMode, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import {Logo} from "../Logo";
import { ConnectWalletButton } from "../ConnectWalletButton/ConnectWalletButton";

export const Nav = () =>{
    return (
    <HStack w="full" p={5} bgColor={useColorModeValue("gray.50", "gray.900")}>
        <Logo />
        <Spacer />
        <ConnectWalletButton />
        <ColorModeSwitcher />

    </HStack>
    );
};