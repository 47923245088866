import { useContractFunction, useEtherBalance, useEthers, useTokenBalance } from '@usedapp/core';
import { useSupportedNetworks } from '../../constants/SupportedNetworks';

import { Text, Button, Center, Divider, HStack, Heading, Input, Spacer, Tag, VStack, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Logo } from '../Logo';
import { formatEther, formatUnits, parseUnits } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';
import { usePresalePrice } from '../../hooks/PresaleHooks';






export const SwapUI = () => {
    const {chainId, account} = useEthers();
    const toast = useToast();
    const currentNetwork = useSupportedNetworks[chainId!];
    const userNativeBalanceInWei: BigNumber | undefined = 
    useEtherBalance(account);

    const userTokenBalanceInWei: BigNumber | undefined= useTokenBalance(
        currentNetwork?.token?.contractAddress, account
    );

        const presalePrice = usePresalePrice();

        const [UserInput, setUserInput] = useState <{
            ethInput: string;
            tokenInput: string;
        }>({
            ethInput:"",
            tokenInput:"",
        });

        const {send, state, resetState} = useContractFunction(currentNetwork?.presaleContractInterface, "buy" );

        const[transactionStatus, setTransactionStatus]= useState<
        "No" | "Pending Signature" | "Mining" 
        >("No");

    const handleEthInput = (e:any) => {
        const value = e.target.value;
        setUserInput((prev) => ({ ...prev, ethInput: value, 
            tokenInput:value?.length === 0 ? "": Number(value*presalePrice).toFixed(5),
        }));
    };
    const handleTokenInput = (e:any) => {
        const value = e.target.value;
        setUserInput((prev) => ({ ...prev, 
            ethInput:value?.length === 0 ? "": Number(value/presalePrice).toFixed(5),
            tokenInput: value,
        }));
    };

    const handleSwap = () => {
        if (
            Number(formatEther(userNativeBalanceInWei ?? 0)) <
            Number(UserInput?.ethInput)
        ) {
            toast({
                title: 'Insufficiant Balance.',
                description: "Please enter the ammount equal or less than your balance.",
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
        } else if (Number(UserInput?.ethInput) <= 0){
            toast({
                title: 'please enter valid amount.',
                description: "Please enter amount more than 0.",
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
        } else {
            proceedSwap();
        }
    };
    const proceedSwap = async() => {
        await send({
            value: parseUnits(UserInput?.ethInput),
        });
    };

    useEffect(() => {
        if (state.status === "Exception"){
            toast({
                title: 'Error',
                description: state.errorMessage,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              setTransactionStatus("No");
              resetState();
        } else if(state.status === "PendingSignature"){
            setTransactionStatus("Pending Signature");
        } else if(state.status === "Mining"){
            setTransactionStatus("Mining");
        } else if (state.status === "Success"){
            toast({
                title: 'Transaction Success',
                description: (
                   <VStack w="full">
                    <Text>Your transaction hash</Text>
                    <Button
                    as ="a"
                    target='_blank'
                    href={currentNetwork?.nativeFunctions?.getExplorerTransactionLink(
                        state.receipt?.transactionHash!
                    )}
                    colorScheme='yellow'
                    borderRadius="2xl"
                    >
                        View on {currentNetwork?.explorerLink?.name}
                    </Button>
                   </VStack>

                ),
                status: 'success',
                duration: 50000,
                isClosable: true,
              });

              setTransactionStatus("No");
        }
        
    },[state.status]);

    console.log(state);




  return (
  <VStack 
  w={300}
  bgColor={useColorModeValue("gray.50", "gray.900")}
  borderRadius= "3xl"
  boxShadow="md"
  p={5}
  spacing={5}
  >
    <HStack>
        <Heading size="md">swap</Heading>
        <Logo />
    </HStack>
    <Center w="full">
        <Divider />
    </Center>
    <VStack w="full">
    <HStack w="full">
        <Tag>Balance</Tag>
        <Spacer />
        <Tag colorScheme={'green'}>{Number(formatEther(userNativeBalanceInWei ?? 0)).toFixed(3)}{""}
        {currentNetwork?.native?.symbol}
        </Tag>
    </HStack>
    <Input 
        placeholder={`Please entre the ${currentNetwork?.native?.symbol} value`} 
        h={16}
        borderRadius="3xl"
        value={UserInput?.ethInput}
        onChange={handleEthInput} 
    ></Input>
    </VStack>
    <VStack w="full">
    <HStack w="full">
        <Tag>Balance</Tag>
        <Spacer />
        <Tag colorScheme={'yellow'}>
            {Number(
                formatUnits
                (userTokenBalanceInWei ?? 0, 
                currentNetwork?.token?.decimals
                )).toFixed(3)}{""}
        {currentNetwork?.token?.symbol}
        </Tag>
    </HStack>
    <Input
         placeholder={`Please entre the ${currentNetwork?.token?.symbol} value`} 
         h={16}
         borderRadius="3xl" 
         value={UserInput?.tokenInput}
         onChange={handleTokenInput}
    ></Input>
    </VStack>
    <Button w="full" h={20} borderRadius="3xl" colorScheme='green' onClick={handleSwap} isDisabled={UserInput?.ethInput.length === 0}
        isLoading={
            transactionStatus === "Mining" || transactionStatus === "Pending Signature"}
        loadingText={`${state.status}...`}
    >
        Swap
    </Button>
  </VStack>
  );
};
function useEthersBalance(account: string | undefined) {
    throw new Error('Function not implemented.');
}

