import { ChakraProvider, ColorModeScript, theme } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import * as serviceWorker from "./serviceWorker"
import { AvalancheTestnet, Config, DAppProvider } from "@usedapp/core"


const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

const dappConfig: Config = {
 readOnlyUrls:{
  [AvalancheTestnet.chainId] : "https://api.avax-test.network/ext/bc/C/rpc",
 },
};

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <DAppProvider config={dappConfig}>
      <App />
      </DAppProvider>
      
    </ChakraProvider>  
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();


